import React, { useState, useRef, useEffect } from 'react';
import * as styles from '../styles/homePage.module.css';
import Logo from '../Logo';
import Layout from './components/Layout';
import { Link, graphql } from 'gatsby';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import Testimonial from '../utils/testimonyDummy.json';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default function HomePage({ data }) {
  const projects = data.allProjectDummyJson.nodes;
  const blogs = data.allBlogDummyJson.nodes;
  const [width, setWidth] = useState(0);
  const carousel = useRef(null);
  const [off, setOff] = useState(0);
  const handleScroll = () => setOff(window.pageYOffset);

  let bannerHeader = useRef(null);
  let bannerHeader1 = useRef(null);
  let bannerSpan = useRef(null);
  let bannerSpan1 = useRef(null);
  let bannerOverlay = useRef(null);

  const tl = gsap.timeline();
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setWidth(carousel.current.scrollWidth - 1100);
    console.log(carousel.current.scrollWidth - 590);
  }, []);

  useEffect(() => {
    tl.from(bannerHeader, 1, {
      y: 100,
      ease: 'power4.out',
      delay: -0.4,
      skewX: 10,
      stagger: {
        amount: 0.3,
      },
    })
      .from(bannerHeader1, 1, {
        y: 100,
        ease: 'power4.out',
        delay: -0.4,
        skewX: 10,
        stagger: {
          amount: 0.3,
        },
      })
      .from(bannerSpan, 1, {
        y: 100,
        ease: 'power4.out',
        delay: -0.4,
        skewX: 10,
        stagger: {
          amount: 0.3,
        },
      })
      .from(bannerSpan1, 1, {
        y: 100,
        ease: 'power4.out',
        delay: -0.4,
        skewX: 10,
        stagger: {
          amount: 0.3,
        },
      })
      .to(bannerOverlay, 1, {
        height: 0,
        ease: 'expo.inOut',
        stagger: 0.4,
      });
  }, []);

  return (
    <Layout>
      <div className={styles.home_container}>
        <div className={styles.home_banner_wrapper}>
          <div className={styles.home_banner_inner}>
            <h2>
              <div
                className={styles.line}
                ref={(el) => {
                  bannerHeader = el;
                }}>
                <span
                  ref={(el) => {
                    bannerSpan = el;
                  }}>
                  A better way of designing <b>smarter </b>and
                </span>
              </div>
              <div
                className={styles.line}
                ref={(el) => {
                  bannerHeader1 = el;
                }}>
                <span
                  ref={(el) => {
                    bannerSpan1 = el;
                  }}>
                  <b>efficient </b>digital experiences
                </span>
              </div>
            </h2>
            <div className={styles.home_banner_inner_scroll}>
              <p>scroll to learn more</p>
              <div className={styles.home_banner_arrow}>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className={styles.logo_wrapper}>
              <div className={styles.logo}>
                {Logo.map((logos) => {
                  return (
                    <div>
                      <img src={logos} alt='logo' className={styles.imgicon} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={styles.bannerOverlay}>
            <div
              className={styles.bannerOverlay_inner}
              ref={(el) => {
                bannerOverlay = el;
              }}></div>
          </div>
        </div>
        <section className={styles.section_content1}>
          <div className={styles.home_section_offer_wrapper}>
            <div className={styles.home_section_offer_inner}>
              <div className={styles.home_section_offer_inner_img}>
                <img
                  src='/homeSectionImg1.png'
                  alt=''
                  className={styles.home_section_offer_inner_img1}
                />
                <img
                  src='/homeSectionImg2.png'
                  alt=''
                  className={styles.home_section_offer_inner_img2}
                />
                <img
                  src='/homeSectionImg3.png'
                  alt=''
                  className={styles.home_section_offer_inner_img3}
                />
              </div>

              <div className={styles.home_section_offer_inner_content}>
                <h4>Bring your ideas to life</h4>
                <h3>
                  Promote Trust, Drive <br /> Growth
                </h3>
                <div className={styles.about_offer_content}>
                  <div className={styles.home_offer_content_inner}>
                    <img src='/dollar.png' alt='' />
                    <p>75% cost covered by TVP (Hong Kong)</p>
                  </div>

                  <div className={styles.home_offer_content_inner}>
                    <img src='/clock.png' alt='' />
                    <p>8 hours maximum response time</p>
                  </div>

                  <div className={styles.home_offer_content_inner}>
                    <img src='/tool.png' alt='' />
                    <p>100% project completion rate</p>
                  </div>
                </div>
                <Link to='/About'>
                  <button
                    className={styles.home_section_offer_inner_content_button}>
                    about us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.section_content2}>
          <div className={styles.section_content2_inner}>
            <div>
              <h4>Bring your ideas to life</h4>
              <h3>
                We help create strategies,
                <br /> design {'&'} development.
              </h3>
            </div>
            <img src='/24-hours.png' alt='' />
          </div>

          <div className={styles.section_content2_inner_wrapper}>
            <div className={styles.section_content2_inner_container}>
              <div>
                <img src='/Vector.png' alt='' />
                <div className={styles.section_content2_inner_content}>
                  <h5>Software Dev</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac
                    augue leo leo nunc.
                  </p>
                </div>
                <span>{'>'}</span>
              </div>
              <div>
                <img src='/Vector.png' alt='' />
                <div className={styles.section_content2_inner_content}>
                  <h5>Software Dev</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac
                    augue leo leo nunc.
                  </p>
                </div>
                <span>{'>'}</span>
              </div>
              <div>
                <img src='/Vector.png' alt='' />
                <div className={styles.section_content2_inner_content}>
                  <h5>Software Dev</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac
                    augue leo leo nunc.
                  </p>
                </div>
                <span>{'>'}</span>
              </div>
              <div>
                <img src='/Vector.png' alt='' />
                <div className={styles.section_content2_inner_content}>
                  <h5>Software Dev</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac
                    augue leo leo nunc.
                  </p>
                </div>
                <span>{'>'}</span>
              </div>
            </div>
            <hr />
            <div className={styles.quote}>
              <img src='/quote.png' alt='' />
              <p>
                “This success wouldn’t have been possible without LabLamb Works’
                intervention. We’ve patronized other agencies but no one does it
                like they do. it’s crazy!”
              </p>
              <div className={styles.quote_author}>
                <div className={styles.quote_author_inner}>
                  <p>David Sharpe Ryan</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={styles.section_content3}>
          <div className={styles.section_content3_inner}>
            <div className={styles.section_content3_inner_header}>
              <div>
                <h4>Bring your ideas to life</h4>
                <h3>projects</h3>
              </div>
              <Link to='/Projects/Project'>view more projects</Link>
            </div>
            <div className={styles.home_project_showcase_carousel_wrapper}>
              <motion.div
                className={styles.home_project_showcase_carousel}
                ref={carousel}>
                <motion.div
                  drag='x'
                  dragConstraints={{ right: 0, left: -width }}
                  className={styles.home_project_showcase_inner_carousel}>
                  {projects.map((data) => {
                    return (
                      <motion.div>
                        <div
                          className={
                            styles.home_project_showcase_carousel_image
                          }>
                          <img src={data.img} alt='images' />
                        </div>
                        <div
                          className={
                            styles.home_project_showcase_carousel_image_content
                          }>
                          <span>{data.subTopic}</span>
                          <p>{data.topic}</p>
                          <Link
                            to={'/Projects/Project/' + data.slug}
                            className={styles.home_project_showcase_link}>
                            view project <span>{'>'}</span>
                          </Link>
                        </div>
                      </motion.div>
                    );
                  })}
                </motion.div>
              </motion.div>
            </div>
          </div>
        </section>

        <section className={styles.section_content4}>
          <div className={styles.section_content4_inner_container}>
            <div className={styles.section_inner_content}>
              <h4>Bring your ideas to life</h4>
              <h3>
                We’re in the business of <br /> making dreams a reality
              </h3>
              <p>
                Whether you are seeking industry tips, looking to rebrand your
                business strategies or bring an idea to life, our creative and
                technical teams are here to help you achieve the perfection you
                need.
              </p>
            </div>
            <div>
              <img src='/lady.png' alt='' className={styles.section4_img1} />
              <img src='/lady.png' alt='' className={styles.section4_img2} />
              <img src='/lady.png' alt='' className={styles.section4_img3} />
              <img
                src='/gradient.png'
                alt=''
                className={styles.section4_img_bg}
              />
            </div>
          </div>

          <div className={styles.home_slider_wrapper}>
            <div className={styles.testimonial_card_wrapper}>
              <motion.div
                className={styles.testimonial_carousel}
                ref={carousel}>
                <motion.div
                  drag='x'
                  dragConstraints={{ right: 0, left: -width }}
                  className={styles.inner_carousel}>
                  {Testimonial.map((data) => {
                    return (
                      <motion.div className={styles.testimonial_inner}>
                        <div className={styles.testimonial_inner_content}>
                          <div className={styles.testimonial_inner_card}>
                            <div className={styles.testimonial_user_profile}>
                              <img src={data.img} alt='' />
                              <p className={styles.testimonial_user_name}>
                                {data.Name}
                              </p>
                              <p
                                className={styles.testimonial_user_companyName}>
                                {data.companyName}
                              </p>
                            </div>

                            <p className={styles.testimonial_text}>
                              {data.content}
                            </p>
                          </div>
                        </div>
                      </motion.div>
                    );
                  })}
                </motion.div>
              </motion.div>
            </div>
          </div>
        </section>

        <section className={styles.section_content5}>
          <div className={styles.section_content5_inner_container}>
            <div className={styles.section_content5_inner_header}>
              <div className={styles.section_inner_content}>
                <h4>Keep up to date with what’s trending and latest</h4>
                <h3>The Lab Blog</h3>
              </div>
              <Link to='/Blog/blogPost'>Read more stories</Link>
            </div>

            <div className={styles.blog_showcase_carousel_wrapper}>
              <motion.div
                className={styles.blog_showcase_carousel}
                ref={carousel}>
                <motion.div
                  drag='x'
                  dragConstraints={{ right: 0, left: -width }}
                  className={styles.blog_showcase_inner_carousel}>
                  {blogs.map((data) => {
                    return (
                      <motion.div>
                        <div className={styles.blog_showcase_carousel_image}>
                          <img src={data.img} alt='images' />
                        </div>
                        <div
                          className={
                            styles.blog_showcase_carousel_image_content
                          }>
                          <span>{data.subTopic}</span>
                          <h6>{data.topic}</h6>
                          <Link
                            to={'/Blog/blogPost/' + data.slug}
                            className={styles.blog_showcase_link}>
                            View post
                          </Link>
                        </div>
                      </motion.div>
                    );
                  })}
                </motion.div>
              </motion.div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ProjectDetail($slug: String) {
    projectDummyJson(slug: { eq: $slug }) {
      topic
      subTopic
      img
      bannerHeader
      bannerImg
      client
      cost
      tags
      stack
      overview
      categories
      location
      duration
      team_member1
      team_member2
      team_member3
      team_member4
      team_member5
      team_member6
      team_member1_name
      team_member2_name
      team_member3_name
      team_member4_name
      team_member5_name
      team_member6_name
      job1
      job2
      job3
      job4
      job5
      job6
      id
    }
    allProjectDummyJson {
      nodes {
        id
        img
        topic
        subTopic
        slug
      }
    }
    allBlogDummyJson {
      nodes {
        subTopic
        topic
        img
        id
        slug
      }
    }
  }
`;
